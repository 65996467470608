/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';
import Dots from 'components/addons/general-dots';
import styles from './general-pageheader.module.scss';
import Img from 'gatsby-image';

const Homepage = ({ items }) => (
  <div itemscope itemtype="http://schema.org/Article" className={styles.generalHeader}>
    <div className={styles.generalHeaderbg}>
      <Img
        fluid={
          items.frontmatter.image
            ? items.frontmatter.image.childImageSharp.fluid
            : {}
        }
        alt={items.frontmatter.title}
        className={styles.generalHeaderbgWrap}
      />
    </div>
    <Dots />
    <div className={[styles.meta, styles.container].join(' ')}>
      <h1 itemprop="name">{items.frontmatter.title}</h1>
      <div className={styles.subMeta}>
        <time>{items.frontmatter.date}</time>
        <span itemprop="author" className={styles.author}>
          &nbsp;by&nbsp;{items.frontmatter.author}
        </span>
      </div>
    </div>
    <div className={styles.category}>
      <span>{items.frontmatter.category}</span>
    </div>
  </div>
);

Homepage.propTypes = {
  items: PropTypes.object.isRequired,
};

export default Homepage;
