/* eslint-disable react/no-unknown-property */
import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import PageHeader from '../components/addons/general-pageheader';
import SimilarArticles from '../components/addons/SimilarArticles';
import PropTypes from 'prop-types';
import styles from './blog-post.module.scss';
import './blog-post.scss';

const blogPost = ({ data }) => {
  const post = data.markdownRemark;
  const isWhite = true;
  return (
    <div>
      <PageHeader items={data.markdownRemark} />
      <Layout isWhite={isWhite}>
        <Helmet
          title={post.frontmatter.title}
          meta={[
            { name: 'description', content: post.frontmatter.excerpt },
            {
              name: 'keywords',
              content:
                post.frontmatter.tags +
                ', ' +
                post.frontmatter.category +
                ', blog, design, ux/ui, writing, pisanie, kod, ciekawostki, opinie',
            },
          ]}
        />

        <article itemscope itemtype="http://schema.org/Article" className={[styles.row, styles.singlePost].join(' ')}>
          <div
            className={[styles.column6, styles.centerMargin, styles.post].join(
              ' '
            )}
          >
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            <p>Autor obrazka głównego: {post.frontmatter.imageAuthor}</p>
            <p>Tagi: {post.frontmatter.tags.map(tag=>tag+', ')}</p>
          </div>
        </article>
        <SimilarArticles category={post.frontmatter.category} tags={post.frontmatter.tags} currentArticleSlug={data.markdownRemark.fields.slug}/>
      </Layout>
    </div>
  );
};

blogPost.propTypes = {
  data: PropTypes.object.isRequired,
};

export default blogPost;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1150) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        author
        tags
        imageAuthor
        category
        image {
          childImageSharp {
            fluid(maxHeight: 750, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
