/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import SVG from 'react-svg';
import styles from './ArticleCard.module.scss';

export const GhostArticleCard = () => (
  <article
    className={[styles.column, styles.articleCard].join(' ')}
  ></article>
);

const ArticleCard = props => {
  const { title, image, category, description, date, slug } = props;
  return (
    <Link to={slug} className={styles.readMore}>
      <article
        itemscope
        itemtype="http://schema.org/Article"
        className={[
          styles.column,
          styles.articleCard,
          styles.postPreview,
        ].join(' ')}
      >
        <div className={styles.postImage}>
          <Img
            className={styles.postImageInner}
            fluid={image ? image.childImageSharp.fluid : {}}
            alt={title}
          />
          <SVG
            className={styles.postImageBG}
            src={
              '/icons/blog-bg-' +
              (Math.floor(Math.random() * (Math.floor(6) - Math.ceil(1))) +
                Math.ceil(1)) +
              '.svg'
            }
          />
          <div className={[styles.frame, styles.frameStyle].join(' ')} />
        </div>
        <section className={styles.preview}>
          <header className={styles.postHeader}>
            <div className={styles.meta}>
              <span className={styles.category}>{category}</span>
              <span className={styles.dateauthor}>{date}</span>
            </div>
            <h3 itemprop="name">{title}</h3>
          </header>
          <p className={styles.copy}>{description}</p>
          <SVG className={styles.arrow} src={'/icons/arrow_right.svg'} />
        </section>
      </article>
    </Link>
  );
};

export default ArticleCard;

ArticleCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
  slug: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  category: PropTypes.string,
  description: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};
